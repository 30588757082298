

export const PlatformList = [
    {
        title:"平台配送",
        state:"0"
    },{
        title:"商家配送",
        state:"1"
    }
]

export const StoreType = [
    {
        title:"外卖",
        state:"0"
    },{
        title:"超市",
        state:"1"
    },{
        title:"零食铺子",
        state:"2"
    },{
        title:"活动专区",
        state:"3"
    },
]

export const OrderStatus = [
    {
        title:"未支付",
        state:"0"
    },{
        title:"待商家接单",
        state:"1"
    },{
        title:"待配送员接单",
        state:"2"
    },{
        title:"待取货",
        state:"3"
    },{
        title:"待送达",
        state:"4"
    },{
        title:"已完成",
        state:"5"
    },{
        title:"订单取消",
        state:"6"
    },{
        title:"已失效",
        state:"7"
    },{
        title:"已退款",
        state:"8"
    },{
        title:"异常",
        state:"9"
    },
]


