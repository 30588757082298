import Storage from "@/util/Storage"
import {GetShopFeedbackList} from "@/Api/Shop";
import {FeedbackShopStore} from "@/views/Shop/FeedbackShop/Store";


// 搜索异常订单
export function getFindExpress() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(GetShopFeedbackList({ userId,token },FeedbackShopStore.getFeedbackShopUpData).then(res=>{
        return res.data || []
    }))
}
