import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import FeedbackShopStoreType from "@/views/Shop/FeedbackShop/Store/indexType";
import {GetShopFeedbackListType} from "@/Api/Shop/interfaceType";

@Module({
    name:"FeedbackShop",
    store,
    dynamic: true,
})
export default class FeedbackShop extends VuexModule implements FeedbackShopStoreType{
    FeedbackShopList: any[] = [];
    get getFeedbackShopList(){
        return this.FeedbackShopList
    }
    @Mutation
    SetFeedbackShopList(data: any[]): void {
        this.FeedbackShopList = data
    }

    FeedbackShopUpData: GetShopFeedbackListType = {
        pageNo: 1,
        pageSize: 15,
        userId:""
    };
    get getFeedbackShopUpData(){ return this.FeedbackShopUpData }
    @Mutation
    SetFeedbackShopUpData(data: GetShopFeedbackListType): void {
        this.FeedbackShopUpData = data
    }

    FeedbackShopClear = false;
    get getFeedbackShopClear(){ return this.FeedbackShopClear }
    @Mutation
    SetFeedbackShopClear(data: boolean): void {
        if ( data ){
            let time = setTimeout(()=>{
                this.FeedbackShopClear = false
                clearTimeout(time)
            },3000)
        }
        this.FeedbackShopClear = data
    }

}

export const FeedbackShopStore = getModule( FeedbackShop )
